import { render, staticRenderFns } from "./DisorderTemplate.vue?vue&type=template&id=fec18f66&scoped=true&"
import script from "./DisorderTemplate.vue?vue&type=script&lang=js&"
export * from "./DisorderTemplate.vue?vue&type=script&lang=js&"
import style0 from "./DisorderTemplate.vue?vue&type=style&index=0&id=fec18f66&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fec18f66",
  null
  
)

export default component.exports