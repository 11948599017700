<template>
  <div>
    <v-sheet class="pa-8 mb-8">
      <div class="text-center">
        <h1 class="display-2 mb-5">{{ heading }}</h1>
      </div>
      <div class="text-center mb-5">
        <slot> </slot>
      </div>
      <div v-if="sources" class="mb-5">
        <h4 class="mb-1">{{ $t("sources") }}</h4>
        <p v-for="source in sources" :key="source" class="mb-1 caption">
          {{ source }}
        </p>
      </div>
      <v-row justify="center" align="center" class="text-center">
        <v-col>
          <v-btn v-if="previous" color="accent" :to="previous.url">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t(previous.slug) }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="next" color="accent" :to="next.url">
            {{ $t(next.slug) }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    sources: {
      type: Array,
      required: false
    },
    next: {
      type: Object,
      required: false
    },
    previous: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    mail: "mail@bauchgefuehle-giessen.de"
  })
};
</script>
<style scoped>
.custom-padding {
  padding: 2rem 4.5rem 1rem 4.5rem;
}
.citation {
  font-size: large;
  font-style: italic;
  color: #000;
}
.citation-author {
  font-size: large;
  color: #000;
}
.link {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>
