<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('biggerexia')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p v-for="paragraph in $t('disorders-biggerexia')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "biggerexia",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "night-eating-syndrom",
      url: "/disorders/night-eating-syndrom"
    },
    previous: {
      slug: "orthorexia",
      url: "/disorders/orthorexia"
    },
    sources: [
      "[1] Waldorf M, Cordes M, Taube C, Trunk J, Vocks S. Muskeldysmorphie. In: Schnell T, Schnell K, eds. Handbuch Klinische Psychologie. Springer; 2019:1-13. doi:10.1007/978-3-662-45995-9_46-1",
      "[2] Mitchell L, Murray SB, Cobley S, et al. Muscle Dysmorphia Symptomatology and Associated Psychological Features in Bodybuilders and Non-Bodybuilder Resistance Trainers: A Systematic Review and Meta-Analysis. Sports Med. 2017;47(2):233-259. doi:10.1007/s40279-016-0564-3"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("biggerexia"),
          disabled: true
        }
      ];
    }
  }
};
</script>
