<template>
  <v-sheet>
    <v-breadcrumbs :items="items" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled" exact>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-sheet>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
